import { Routes } from '@angular/router'

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./landing/landing.component').then((c) => c.LandingComponent)
  },
  {
    path: 'confirm-email',
    loadComponent: () =>
      import('./confirm-email/confirm-email.component').then(
        (c) => c.ConfirmEmailComponent
      )
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./reset-password/reset-password.component').then(
        (c) => c.ResetPasswordComponent
      )
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./privacy-policy/privacy-policy.component').then(
        (c) => c.PrivacyPolicyComponent
      )
  },
  {
    path: 'app-privacy-policy',
    loadComponent: () =>
      import('./app-privacy-policy/app-privacy-policy.component').then(
        (c) => c.AppPrivacyPolicyComponent
      )
  },
  {
    path: 'cookie-policy',
    loadComponent: () =>
      import('./cookie-policy/cookie-policy.component').then(
        (c) => c.CookiePolicyComponent
      )
  },
  {
    path: 'company-registration-request',
    loadComponent: () =>
      import(
        './company-registration-request/company-registration-request.component'
      ).then((c) => c.CompanyRegistrationRequestComponent)
  },
  {
    path: 'assistance-request',
    loadComponent: () =>
      import('./assistance-request/assistance-request.component').then(
        (c) => c.AssistanceRequestComponent
      )
  },
  {
    path: 'delete-account-info',
    loadComponent: () =>
      import('./delete-account-info/delete-account-info.component').then(
        (c) => c.DeleteAccountInfoComponent
      )
  },
  {
    path: 'download-app',
    loadComponent: () =>
      import('./download-app/download-app.component').then(
        (c) => c.DownloadAppComponent
      )
  },
  {
    path: 'error-page',
    loadComponent: () =>
      import('./error-page/error-page.component').then(
        (c) => c.ErrorPageComponent
      )
  },
  {
    path: 'explore',
    loadComponent: () =>
      import('./explore/explore.component').then((c) => c.ExploreComponent)
  },
  {
    path: 'unicatt',
    loadComponent: () =>
      import('./unicatt/unicatt.component').then((c) => c.UnicattComponent)
  },
  {
    path: 'children-safety-policy',
    loadComponent: () =>
      import('./children-safety-policy/children-safety-policy.component').then((c) => c.ChildrenSafetyPolicyComponent)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
]
